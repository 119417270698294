<template>
  <a-row v-if="$route.query.isQuestion === 'true'" :gutter="6" style="padding: 10px">
    <a-col :md="6">
      <div class="left">
        <div class="left-top">
          <h2>欢迎语</h2>
          <p style="font-size: 18px">
            {{ welcome }}
          </p>
        </div>
        <div class="content">
          <div class="list title">
            基本信息
          </div>
          <div class="list" v-for="(d, k) of info" :key="k">
            <p class="label">{{ d.label }}</p>
            <p class="name">{{ d.name }}</p>
          </div>
        </div>
      </div>
    </a-col>
    <a-col :md="18">
      <div class="right">
        <div class="right-top content">
          <div class="list title center">甄别部分</div>
          <div class="list" v-for="(d, k) of distinguish" :key="k">
            <p class="label">{{ d.label }}</p>
            <a-radio-group class="name" v-model="questionForm[d.label.split('、')[0]].list">
              <a-radio v-for="(item, index) of d.name" :key="'item_distinguish_' + index" :value="item.value">
                {{ replaceInfo(item.text) }}
              </a-radio>
            </a-radio-group>
          </div>
          <div class="list title center">
            <span class="label" style="margin-right: 30px">数据是否有效?</span>
            <span>
              <a-radio-group class="name" v-model="dataIsEffective">
                <a-radio :value="true">
                  有效
                </a-radio>
                <a-radio :value="false">
                  无效
                </a-radio>
              </a-radio-group>
            </span>
            <a-button type="primary" @click="dataSubmit">提交</a-button>
          </div>
        </div>
        <div v-show="sampShow" class="content" style="margin-top: 10px">
          <div class="list title center">
            问卷部分
            <div class="content-tips">{{ guide }}</div>
          </div>
          <div class="list" v-for="(d, k) of questionnaire" :key="k">
            <p class="label">{{ d.label }}</p>
            <a-radio-group class="name" v-if="d.type === 'radio'" v-model="questionForm[d.label.split('、')[0]].list">
              <a-radio v-for="(item, index) of d.name" :key="'item_questionnaire_radio_' + index" :value="item.value">
                {{ replaceInfo(item.text) }}
              </a-radio>
              <a-textarea :rows="4" v-if="d.textarea" v-model="questionForm[d.label.split('、')[0]].remark" />
            </a-radio-group>
            <a-checkbox-group class="name" v-if="d.type === 'checkbox'" v-model="questionForm[d.label.split('、')[0]].list">
              <a-row>
                <a-col :span="6" v-for="(item, index) of d.name" :key="'item_questionnaire_checkbox_' + index">
                  <a-checkbox :value="item.value">
                    {{ replaceInfo(item.text) }}
                  </a-checkbox>
                </a-col>
                <a-col>
                  <a-textarea :rows="4" v-model="questionForm[d.label.split('、')[0]].remark" />
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
          <div class="list title center">
            <span class="label" style="margin-right: 30px">样本是否有效?</span>
            <span>
              <a-radio-group class="name" v-model="sampleIsEffective">
                <a-radio :value="true">
                  有效
                </a-radio>
                <a-radio :value="false">
                  无效
                </a-radio>
              </a-radio-group>
            </span>
            <a-button type="primary" @click="sampSubmit">提交</a-button>
          </div>
        </div>
      </div>
    </a-col>
  </a-row>
  <a-card v-else :bordered="false">
    <p class="title">用户信息</p>
    <div class="box">
      <div class="box-child">
        <a-row :gutter="48" v-if="show">
          <a-col :md="24" :sm="24" v-for="(d, k) of dynamicData.customData" :key="k" class="col"> {{ k }}：{{ d }} </a-col>
        </a-row>
        <div v-html="editor" class="view"></div>
      </div>
      <div>
        <a-divider type="vertical" class="divider" />
      </div>
      <div class="box-child">
        <p>营销状态</p>
        <div class="type-box">
          <div v-for="(d, k) of tableData" :key="k" class="type-table">
            <div class="type-params" v-if="d.childFiled.length !== 0">
              {{ d.name }}
            </div>
            <a-radio-group class="type-params" name="id" v-model="form.fieldId" v-else>
              <a-radio :value="d.id">{{ d.name }}-{{ d.type | type }}</a-radio>
            </a-radio-group>
            <a-radio-group name="id" v-model="form.fieldId">
              <a-radio v-for="(c, key) of d.childFiled" :key="key" :value="c.id"> {{ c.name }}-{{ c.type | type }} </a-radio>
            </a-radio-group>
          </div>
        </div>
        <p>备注</p>
        <a-textarea v-model="form.remark" :rows="10" />
        <div class="footer">
          <a-button @click="editHandleOk" type="primary">确定</a-button>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
import { indexDetail, updateMarket } from '@/api/outbound/market'
import { questionDetail, updateCallQuestion } from '@/api/questionnaire/record'
import * as QUESTION from '@/views/questionnaire/questions.js'
import cloneDeep from 'lodash.clonedeep'

const key = 'updateMarket'

export default {
  name: 'Access',
  data() {
    console.log(cloneDeep(QUESTION.question.distinguish))
    console.log(cloneDeep(QUESTION.form))
    return {
      dynamicData: {},
      insideData: {},
      tableData: [],
      editor: '',
      value: '',
      form: {
        fieldId: '',
        id: this.$route.query.id,
        remark: ''
        // taskId: this.$route.query.taskId
      },
      show: false,
      info: [
        { label: '乡镇（街道）', name: '' },
        { label: '村（社区）', name: '' },
        { label: '联系电话', name: '' }
      ],
      // 欢迎语
      welcome: QUESTION.welcome,
      // 引导语(问卷)
      guide: QUESTION.guide,
      // 甄别题
      distinguish: cloneDeep(QUESTION.question.distinguish),
      // 问卷题
      questionnaire: cloneDeep(QUESTION.question.questionnaire),
      // 问卷表单项
      questionForm: cloneDeep(QUESTION.form),
      dataIsEffective: null,
      sampleIsEffective: null,
      sampShow: true
    }
  },
  filters: {
    type(value) {
      if (value === 'SUCCESS') {
        return '成功'
      } else if (value === 'FAIL') {
        return '失败'
      } else {
        return ''
      }
    }
  },
  mounted() {
    if (this.$route.query.isQuestion === 'true') {
      this.questionDetail()
    } else {
      this.detail()
    }
  },
  methods: {
    replaceInfo(text) {
      return text.replace(/{(\d+)}/g, (_match, param) => {
        return this.info[Number(param)].name
      })
    },
    detail(retry = 5) {
      const data = { id: this.$route.query.id, userId: this.$route.query.userId }
      indexDetail(data)
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          const data = res.body
          this.dynamicData = data.record
          this.form.remark = data.record.marketRemark
          this.tableData = data.marketStatus.fields
            .sort((o1, o2) => {
              return o1.id - o2.id
            })
            .map(n => {
              if (n.childFiled.length !== 0) {
                n.childFiled.sort((o1, o2) => {
                  return o1.id - o2.id
                })
              }
              return n
            })
          this.form.fieldId = data.record.marketResultId
          this.editor = data.talkSkill.talkSkill
          this.show = true
          this.loading = false
        })
        .catch(e => {
          if (e.message.includes('timeout') && retry > 0) {
            // 超时重试
            this.detail(--retry)
          } else {
            this.$notification.error({
              message: '提示',
              description: e.message
            })
            this.loading = false
          }
        })
    },
    editHandleOk() {
      updateMarket(this.form)
        .then(res => {
          if (res.status !== 200) {
            this.$notification.error({
              key,
              message: '提示',
              description: res.message
            })
            return
          }
          this.$router.push('/outbound/market')
          this.$notification.success({
            key,
            message: '提示',
            description: res.message
          })
        })
        .catch(e => {
          this.$notification.error({
            key,
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    questionDetail() {
      const { id, questionId } = this.$route.query
      questionDetail({ id, questionId }).then(res => {
        console.log(res)
        if (res.status === 200) {
          this.info[0].name = res.body.townName
          this.info[1].name = res.body.villageName
          this.info[2].name = res.body.phone
          if (res.body.questionData) {
            for (const i in res.body.questionData) {
              this.questionForm[i] = JSON.parse(res.body.questionData[i])
            }
          }
          console.log(this.questionForm)
          this.dataIsEffective = res.body.dataIsEffective
          this.sampleIsEffective = res.body.sampleIsEffective
          if (this.dataIsEffective) {
            this.sampShow = true
          } else {
            this.sampShow = false
          }
        }
      })
    },
    dataSubmit() {
      if (this.dataIsEffective === null || this.dataIsEffective === undefined) {
        this.$notification.error({
          message: '提示',
          description: '请选择是否有效'
        })
        return
      }
      if (this.dataIsEffective === false) {
        this.sampleIsEffective = false
        this.submit()
      } else {
        this.sampShow = true
      }
    },
    sampSubmit() {
      if (this.sampleIsEffective === null || this.sampleIsEffective === undefined) {
        this.$notification.error({
          message: '提示',
          description: '请选择是否有效'
        })
        return
      }
      this.submit()
    },
    submit() {
      const { dataIsEffective, questionForm, sampleIsEffective } = this
      const form = { ...questionForm }
      for (const i in form) {
        form[i] = JSON.stringify(form[i])
      }
      const { id } = this.$route.query
      updateCallQuestion({ dataIsEffective, sampleIsEffective, questionData: form, id: id })
        .then(res => {
          if (res.status !== 200) {
            this.$notification.error({
              key,
              message: '提示',
              description: res.message
            })
            return
          }
          this.$router.push('/questionnaire/questionnaireRecordManage')
          this.$notification.success({
            key,
            message: '提示',
            description: res.message
          })
        })
        .catch(e => {
          this.$notification.error({
            key,
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.confirmLoading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 30px;
  text-align: center;
}
.box {
  display: flex;
}
.box-child {
  width: 49%;
}
.col {
  margin: 10px 0;
}
.type-box {
  border: 1px solid #f0f2f5;
  padding: 20px;
}
.divider {
  height: 100%;
}
.type-table {
  display: flex;
}
.type-params {
  border-right: 1px solid #f0f2f5;
  width: 20%;
}
.type-table > div {
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid #f0f2f5;
}
.footer {
  text-align: center;
  margin-top: 30px;
}
.footer button {
  margin: 0 30px;
}
.view {
  border: 1px solid #ddd;
  padding: 10px 0;
}
/deep/.ant-radio-wrapper:hover {
  color: #1690ff;
}
.type-box .ant-radio-wrapper-checked {
  color: #1690ff;
  font-weight: bold;
}
.left {
  height: calc(100vh - 220px);
  overflow-y: auto;
  background: white;
}
.right {
  height: calc(100vh - 220px);
  overflow-y: auto;
}
.left-top {
  background: #f8fbfc;
  box-sizing: border-box;
  padding: 20px;
}
.content {
  background: #ffffff;
}
.list {
  border-top: 1px solid #eeeeee;
  padding: 20px;
}
.title {
  font-size: 20px;
  color: #1890ff;
}
.label {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
}
.name {
  font-size: 20px;
  font-weight: 400;
  color: #666666;
}
.center {
  text-align: center;
}
.view {
  word-wrap: break-word;
  word-break: normal;
  .selectTdClass {
    background-color: #edf5fa !important;
  }

  table.noBorderTable td,
  table.noBorderTable th,
  table.noBorderTable caption {
    border: 1px dashed #ddd !important;
  }

  table {
    margin-bottom: 10px;
    border-collapse: collapse;
    display: table;
  }

  td,
  th {
    padding: 5px 10px;
    border: 1px solid #ddd;
  }

  caption {
    border: 1px dashed #ddd;
    border-bottom: 0;
    padding: 3px;
    text-align: center;
  }

  th {
    border-top: 1px solid #bbb;
    background-color: #f7f7f7;
  }

  table tr.firstRow th {
    border-top-width: 2px;
  }

  .ue-table-interlace-color-single {
    background-color: #fcfcfc;
  }

  .ue-table-interlace-color-double {
    background-color: #f7faff;
  }

  td p {
    margin: 0;
    padding: 0;
  }
}

.content-tips {
  color: gray;
  font-size: 15px;
  padding-top: 10px;
}
</style>
